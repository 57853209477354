import React from 'react';
import Modal from 'react-modal';
import ImageUploadTool from '../../ImageUploadTool/components/ImageUploadTool';
import { updateCurrentUserProfilePicture } from './restApi';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import styles from './ProfilePicture.module.scss';

const ProfilePicture = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();
  const onResponse = (response) => {
    dispatch({ type: 'ON_RESPONSE', response });
    if (response.feedbackVariant == 'success') {
      dispatch({ type: 'CLOSE_PROFILE_PICTURE_UPDATE_MODAL' });
    }
  };
  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  if (state.loading) {
    return (
      <section id={styles.profilePictureSection}>
        <div id={styles.profilePictureLoading} />
      </section>
    );
  }

  return (
    <>
      <section id={styles.profilePictureSection}>
        <img
          id={styles.profilePicture}
          src={state.user.profilePicturePath}
          onClick={() => {
            if (state.user.isAuthenticatedUser) {
              dispatch({ type: 'OPEN_PROFILE_PICTURE_UPDATE_MODAL' });
            }
          }}
        />
      </section>
      <Modal
        isOpen={state.profilePictureUpdateModalIsOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Profile Picture"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          if (state.user.isAuthenticatedUser) {
            dispatch({ type: 'CLOSE_PROFILE_PICTURE_UPDATE_MODAL' });
          }
        }}
      >
        <ImageUploadTool
          useProviderState={useProfileState}
          useProviderDispatch={useProfileDispatch}
          onSaveClick={(profilePictureBase64String) => {
            updateCurrentUserProfilePicture({
              onResponse,
              onError,
              body: {
                profilePicture: profilePictureBase64String,
                authenticityToken: document.querySelector("meta[name='csrf-token']").getAttribute("content")
              },
            });
          }}
        />
      </Modal>
    </>
  );
};

export default ProfilePicture;
