import React, { useState, useRef } from 'react';

import ReactCrop from 'react-image-crop';

import {
  canvasPreview,
  centerAspectCrop,
  useDebounceEffect,
} from './ImageUploadToolHelpers';

import 'react-image-crop/dist/ReactCrop.css';

import styles from './ImageUploadTool.module.scss';

// This component is a wrapper around ReactCrop from react-image-crop
// https://www.npmjs.com/package/react-image-crop
const ImageUploadTool = ({
  useProviderState,
  useProviderDispatch,
  onSaveClick,
}) => {
  const providerState = useProviderState();
  const providerDispatch = useProviderDispatch();

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          const base64String = reader.result
            ?.replace("data", "")
            ?.replace(/^.+,/, "") ||
            '';

          providerDispatch({
            type: 'UPDATE_PROFILE_PICTURE_BASE_64_STRING_AND_FILE_NAME',
            profilePictureBase64String: base64String,
            profilePictureFileName: `${providerState.user.firstName}_${providerState.user.lastName}_profile_picture.jpg`,
          })
        }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  };

  async function onClickSavePreviewedImage() {
    const previewCanvas = previewCanvasRef.current

    if (!previewCanvas) {
      throw new Error('Crop canvas does not exist')
    }

    const base64String = previewCanvas.toDataURL('image/jpeg', 1.0)
      ?.replace("data", "")
      ?.replace(/^.+,/, "") ||
      '';

    onSaveClick(base64String);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <>
      <div id={styles.profilePictureForm}>
        {!!providerState.userProfilePictureForm.profilePictureBase64String && (
          <div>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={100}
            >
              <img
                id={styles.selectedProfilePicture}
                ref={imgRef}
                alt="Crop me"
                src={`data:image/gif;base64,${providerState.userProfilePictureForm.profilePictureBase64String}`}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        )}
        <input
          id={styles.hiddenImageInput}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
        <button
          className={styles.btn}
          onClick={() => {
            document.getElementById(styles.hiddenImageInput).click();
          }}
        >
          Upload And Crop
        </button>
        <div>
          <label htmlFor="scale-input">Scale:  </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!providerState.userProfilePictureForm.profilePictureBase64String}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!providerState.userProfilePictureForm.profilePictureBase64String}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        <h3 className={styles.title}>Profile Preview</h3>
        {!!completedCrop && (
          <div>
            <canvas
              ref={previewCanvasRef}
              id={styles.profilePicturePreview}
            />
          </div>
        )}
        <button
          className={styles.btn}
          onClick={onClickSavePreviewedImage}
        >
          Save Previewed Image
        </button>
      </div>
    </>
  )
};

export default ImageUploadTool;
