import React from 'react';
import Modal from 'react-modal';
import UserNameUpdateForm from './UserNameUpdateForm';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import styles from './ProfileUserName.module.scss';

const ProfileUserName = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();

  const displayedUserName =
    state.user.isABot ?
    state.user.firstName :
    `${state.user.firstName} ${state.user.lastName} `;

  if (state.loading) {
    return (
      <section id={styles.userNameSection}>
        <div id={styles.userNameLoading} />
      </section>
    );
  }

  return (
    <>
      <section id={styles.userNameSection}>
        <h1 id={styles.userName}>
          {displayedUserName}
          {state.user.isAuthenticatedUser && (
            <a
              id={styles.editUserNameLink}
              onClick={() => {
                if (state.user.isAuthenticatedUser) {
                  dispatch({ type: 'OPEN_USER_NAME_UPDATE_MODAL' });
                }
              }}
            >
                edit
            </a>
          )}
        </h1>
      </section>
      <Modal
        isOpen={state.userNameUpdateModalIsOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update User Name"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          if (state.user.isAuthenticatedUser) {
            dispatch({ type: 'CLOSE_USER_NAME_UPDATE_MODAL' });
          }
        }}
      >
        <UserNameUpdateForm />
      </Modal>
    </>
  );
};

export default ProfileUserName;
