import { createContext, useContext, useReducer } from 'react';

const SandboxStateContext = createContext(null);

const SandboxDispatchContext = createContext(null);

export function SandboxProvider({ children }) {
  const initialSandboxState = {
    loading: true,
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [SandboxState, SandboxDispatch] = useReducer(
    SandboxReducer,
    initialSandboxState
  );

  return (
    <SandboxStateContext.Provider value={SandboxState}>
      <SandboxDispatchContext.Provider value={SandboxDispatch}>
        {children}
      </SandboxDispatchContext.Provider>
    </SandboxStateContext.Provider>
  );
}

export function useSandboxState() {
  return useContext(SandboxStateContext);
}

export function useSandboxDispatch() {
  return useContext(SandboxDispatchContext);
}

function SandboxReducer(SandboxState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...SandboxState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...SandboxState, ...action.feedback };
    }
    case 'GET_FEEDBACK_FROM_LOCAL_STORAGE': {
      return {
        ...SandboxState,
        feedbackMessage: action.feedbackMessage,
        feedbackVariant: action.feedbackVariant,
      };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...SandboxState, ...clearedFeedback };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
