import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";
import { convertObjectKeysToSnakeCase } from "../../Shared/ConvertObjectKeysToSnakeCase";

export const getSkills = ({
  onResponse,
  onError,
  recognizedSkillsPageNumber,
  unrecognizedSkillsPageNumber,
  disallowedSkillsPageNumber,
}) => {
  fetch(
    `/api/skills?recognized_skills_page_number=${recognizedSkillsPageNumber}&unrecognized_skills_page_number=${unrecognizedSkillsPageNumber}&disallowed_skills_page_number=${disallowedSkillsPageNumber}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const createUnrecognizedSkill = ({
  body,
  onResponse,
  onError,
}) => {
  fetch(
    `/api/skills/create_unrecognized_skill`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const recognizeSkill = ({
  body,
  onResponse,
  onError,
}) => {
  fetch(
    `/api/skills/recognize_skill`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const disallowSkill = ({
  body,
  onResponse,
  onError,
}) => {
  fetch(
    `/api/skills/disallow_skill`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const disassociateSkillWithAuthenticatedUser = ({
  body,
  onResponse,
  onError,
}) => {
  fetch(
    `/api/skills/disassociate_skill_with_authenticated_user`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
}
