import { createContext, useContext, useReducer } from 'react';

const SkillsStateContext = createContext(null);

const SkillsDispatchContext = createContext(null);

export function SkillsProvider({ children }) {
  const initialSkillsState = {
    loading: true,
    recognizedSkillsPageNumber: 1,
    unrecognizedSkillsPageNumber: 1,
    disallowedSkillsPageNumber: 1,
    numberOfPagesOfRecognizedSkills: 1,
    numberOfPagesOfUnrecognizedSkills: 1,
    numberOfPagesOfDisallowedSkills: 1,
    recognizedSkills: [],
    unrecognizedSkills: [],
    disallowedSkills: [],
    newUnrecognizedSkillForm: {
      skillName: '',
    },
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [skillsState, skillsDispatch] = useReducer(
    skillsReducer,
    initialSkillsState
  );

  return (
    <SkillsStateContext.Provider value={skillsState}>
      <SkillsDispatchContext.Provider value={skillsDispatch}>
        {children}
      </SkillsDispatchContext.Provider>
    </SkillsStateContext.Provider>
  );
}

export function useSkillsState() {
  return useContext(SkillsStateContext);
}

export function useSkillsDispatch() {
  return useContext(SkillsDispatchContext);
}

function skillsReducer(skillsState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...skillsState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...skillsState, ...action.feedback };
    }
    case 'GET_FEEDBACK_FROM_LOCAL_STORAGE': {
      return {
        ...skillsState,
        feedbackMessage: action.feedbackMessage,
        feedbackVariant: action.feedbackVariant,
      };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...skillsState, ...clearedFeedback };
    }
    case 'UPDATE_NEW_SKILL_NAME': {
      return {
        ...skillsState,
        newUnrecognizedSkillForm: {
          ...skillsState.newUnrecognizedSkillForm,
          skillName: action.skillName,
        },
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
