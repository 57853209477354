import React from 'react';

import styles from './PaginatedTable.module.scss';

const PaginatedTable = ({
  useState,
  useDispatch,
  header,
  contentFromState,
  pageNumberFromState,
  numberOfPagesFromState,
  getFirstPageFromState,
  getPreviousPageFromState,
  getNextPageFromState,
  getLastPageFromState
}) => {
  const state = useState();
  const dispatch = useDispatch();
  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });
  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  if (state.loading) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <>
      <table>
        <thead>
          {header}
        </thead>
        <tbody>
          {contentFromState({
            state,
            onResponse,
            onError,
          })}
        </tbody>
      </table>
      <div id={styles.paginationControls}>
        <div />
        <button
          className={styles.btn}
          onClick={() => {
            getFirstPageFromState({
              state,
              onResponse,
              onError,
            });
          }}
        >
          First Page
        </button>
        <button
          className={styles.btn}
          onClick={() => {
            getPreviousPageFromState({
              state,
              onResponse,
              onError,
            });
          }}
        >
          Previous Page
        </button>
        <p>{pageNumberFromState(state)}</p>
        <button
          className={styles.btn}
          onClick={() => {
            getNextPageFromState({
              state,
              onResponse,
              onError,
            });
          }}
        >
          Next Page
        </button>
        <button
          className={styles.btn}
          onClick={() => {
            getLastPageFromState({
              state,
              onResponse,
              onError,
            });
          }}
        >
          Last Page
        </button>
        <div />
      </div>
    </>
  );
};

export default PaginatedTable;
