import React from 'react';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import { updateCurrentUserName } from './restApi';

import styles from './UserNameUpdateForm.module.scss';

const UserNameUpdateForm = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();
  const onResponse = (response) => {
    dispatch({ type: 'ON_RESPONSE', response });
    if (response.feedbackVariant == 'success') {
      dispatch({ type: 'CLOSE_USER_NAME_UPDATE_MODAL' });
    }
  };
  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  const handleFirstNameTextChange = (e) => {
    dispatch({
      type: 'UPDATE_NEW_USER_FIRST_NAME',
      firstName: e.target.value,
    });
  };

  const handleLastNameTextChange = (e) => {
    dispatch({
      type: 'UPDATE_NEW_USER_LAST_NAME',
      lastName: e.target.value,
    });
  };

  return (
    <div id={styles.userNameUpdateForm}>
      <label className={styles.inputLabel}>First Name</label>
      <input
        className={styles.input}
        id="firstName"
        onChange={handleFirstNameTextChange}
        value={state.userNameUpdateForm.firstName}
      />
      <label className={styles.inputLabel}>Last Name</label>
      <input
        className={styles.input}
        id="lastName"
        onChange={handleLastNameTextChange}
        value={state.userNameUpdateForm.lastName}
      />
      <button
        className={styles.saveBtn}
        onClick={() => {
          updateCurrentUserName({
            onResponse,
            onError,
            body: {
              firstName: state.userNameUpdateForm.firstName,
              lastName: state.userNameUpdateForm.lastName,
              authenticityToken: document.querySelector("meta[name='csrf-token']").getAttribute("content")
            },
          });
        }}
      >
        Save Update
      </button>
    </div>
  );
};

export default UserNameUpdateForm;
