import React from 'react';

import {
  SkillsProvider,
  useSkillsState,
  useSkillsDispatch,
} from './SkillsContext';

import {
  getSkills,
  recognizeSkill,
  disallowSkill,
} from './restApi';

import SkillsLoader from './SkillsLoader';
import NewUnrecognizedSkillForm from './NewUnrecognizedSkillForm';
import PageFeedback from '../../PageFeedback/components/PageFeedback';
import PaginatedTable from '../../PaginatedTable/components/PaginatedTable';

import styles from './Skills.module.scss';

const Skills = () => {
  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  return (
    <SkillsProvider>
      <SkillsLoader />
      <NewUnrecognizedSkillForm />
      <section>
        <h2 className={styles.tableTitle}>
          Unrecognized Skills
        </h2>
        <PaginatedTable
          useState={useSkillsState}
          useDispatch={useSkillsDispatch}
          header={(
            <tr>
              <th>Name</th>
            </tr>
          )}
          contentFromState={({ state, onResponse, onError }) => {
            if (state?.unrecognizedSkills?.length) {
              return state?.unrecognizedSkills?.map(skill => {
                return (
                  <tr key={skill.id}>
                    <td>{skill.displayedName}</td>
                    <td>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          disallowSkill({
                            onResponse,
                            onError,
                            body: {
                              skillId: skill.id,
                              authenticityToken,
                            }
                          });
                        }}
                      >
                        Disallow
                      </button>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          recognizeSkill({
                            onResponse,
                            onError,
                            body: {
                              skillId: skill.id,
                              authenticityToken,
                            }
                          });
                        }}
                      >
                        Recognize
                      </button>
                    </td>
                  </tr>
                );
              });
            } else {
              return (
                <tr>
                  <td />
                  <td>
                    No content
                  </td>
                  <td />
                </tr>
              )
            }
          }}
          pageNumberFromState={(state)=>{
            return state.unrecognizedSkillsPageNumber;
          }}
          numberOfPagesFromState={(state)=>{
            return state.numberOfPagesOfUnrecognizedSkills;
          }}
          getFirstPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: 1,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getPreviousPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: (state.unrecognizedSkillsPageNumber - 1),
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getNextPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: (state.unrecognizedSkillsPageNumber + 1),
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getLastPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: state.numberOfPagesOfUnrecognizedSkills,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
        />
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Recognized Skills
        </h2>
        <PaginatedTable
          useState={useSkillsState}
          useDispatch={useSkillsDispatch}
          header={(
            <tr>
              <th>Name</th>
            </tr>
          )}
          contentFromState={({ state, onResponse, onError }) => {
            if (state?.recognizedSkills?.length) {
              return state?.recognizedSkills?.map(skill => {
                return (
                  <tr key={skill.id}>
                    <td>{skill.name}</td>
                    <td>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          disallowSkill({
                            onResponse,
                            onError,
                            body: {
                              skillId: skill.id,
                              authenticityToken,
                            }
                          });
                        }}
                      >
                        Disallow
                      </button>
                    </td>
                  </tr>
                );
              });
            } else {
              return (
                <tr>
                  <td />
                  <td>
                    No content
                  </td>
                  <td />
                </tr>
              )
            }
          }}
          pageNumberFromState={(state)=>{
            return state.recognizedSkillsPageNumber;
          }}
          numberOfPagesFromState={(state)=>{
            return state.numberOfPagesOfRecognizedSkills;
          }}
          getFirstPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: 1,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getPreviousPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: (state.recognizedSkillsPageNumber - 1),
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getNextPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: (state.recognizedSkillsPageNumber + 1),
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
          getLastPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.numberOfPagesOfRecognizedSkills,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: state.disallowedSkillsPageNumber,
            });
          }}
        />
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Disallowed Skills
        </h2>
        <PaginatedTable
          useState={useSkillsState}
          useDispatch={useSkillsDispatch}
          header={(
            <tr>
              <th>Name</th>
            </tr>
          )}
          contentFromState={({ state, onResponse, onError }) => {
            if (state?.disallowedSkills?.length) {
              return state?.disallowedSkills?.map(skill => {
                return (
                  <tr key={skill.id}>
                    <td>{skill.name}</td>
                    <td>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          recognizeSkill({
                            onResponse,
                            onError,
                            body: {
                              skillId: skill.id,
                              authenticityToken,
                            }
                          });
                        }}
                      >
                        Recognize
                      </button>
                    </td>
                  </tr>
                );
              });
            } else {
              return (
                <tr>
                  <td />
                  <td>
                    No content
                  </td>
                  <td />
                </tr>
              )
            }
          }}
          pageNumberFromState={(state)=>{
            return state.disallowedSkillsPageNumber;
          }}
          numberOfPagesFromState={(state)=>{
            return state.numberOfPagesOfDisallowedSkills;
          }}
          getFirstPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: 1,
            });
          }}
          getPreviousPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: (state.disallowedSkillsPageNumber - 1),
            });
          }}
          getNextPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: (state.disallowedSkillsPageNumber + 1),
            });
          }}
          getLastPageFromState={({
            state,
            onResponse,
            onError,
          })=>{
            getSkills({
              onResponse,
              onError,
              recognizedSkillsPageNumber: state.recognizedSkillsPageNumber,
              unrecognizedSkillsPageNumber: state.unrecognizedSkillsPageNumber,
              disallowedSkillsPageNumber: state.numberOfPagesOfDisallowedSkills,
            });
          }}
        />
      </section>
      <PageFeedback
        useState={useSkillsState}
        useDispatch={useSkillsDispatch}
      />
    </SkillsProvider>
  );
};

export default Skills;
