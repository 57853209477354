import { useEffect } from 'react';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import { getUser } from './restApi';

const ProfileLoader = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();

  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    if (state.user.id) {
      getUser({
        id: state.user.id,
        onResponse,
        onError,
      });
    }
  }, []);

  useEffect(() => {
    if (!!state.user.firstName && !!state.user.lastName) {
      document.title = `Isibute | ${state.user.firstName} ${state.user.lastName}`;
    }
  }, [state.user.firstName, state.user.lastName]);

  return null;
};

export default ProfileLoader;
