import React from 'react';
import Modal from 'react-modal';
import UserBiographyUpdateForm from './UserBiographyUpdateForm';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import styles from './ProfileUserBiography.module.scss';

const ProfileUserBiography = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();

  const openUserBiographyUpdateModalIfPermitted = () => {
    if (state.user.isAuthenticatedUser) {
      dispatch({ type: 'OPEN_USER_BIOGRAPHY_UPDATE_MODAL' });
    }
  };

  const closeUserBiographyUpdateModalIfPermitted = () => {
    if (state.user.isAuthenticatedUser) {
      dispatch({ type: 'CLOSE_USER_BIOGRAPHY_UPDATE_MODAL' });
    }
  };

  const biographyStyling =
    !state.user.biography ?
    styles.visibleOnlyToYou :
    null;
  const noBiographyMessage = 'This section is currently only visible to you since you do not currently have a bio to display.';
  const biographyContent = state.user.biography || noBiographyMessage;
  const editControl =
    state.user.isAuthenticatedUser ?
    <a id={styles.editBiographyLink} onClick={openUserBiographyUpdateModalIfPermitted}>edit</a> :
    null;

  if (state.loading) {
    return (
      <section id={styles.userBiographySection}>
        <div id={styles.userBiographyLoading} />
      </section>
    );
  }

  return (
    <>
      {(state.user.biography || state.user.isAuthenticatedUser) && (
        <section id={styles.userBiographySection}>
          <div>
            <h2 className={biographyStyling}>
              Bio
            </h2>
            <p className={biographyStyling}>
              {biographyContent} {editControl}
            </p>
          </div>
        </section>
      )}
      <Modal
        isOpen={state.userBiographyUpdateModalIsOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Biography"
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeUserBiographyUpdateModalIfPermitted}
      >
        <UserBiographyUpdateForm />
      </Modal>
    </>
  );
};

export default ProfileUserBiography;
