import { createContext, useContext, useReducer } from 'react';

const ProfileStateContext = createContext(null);

const ProfileDispatchContext = createContext(null);

export function ProfileProvider({
  userId,
  children,
}) {
  const initialState = {
    loading: true,
    user: {
      id: userId,
    },
    profilePictureUpdateModalIsOpen: false,
    userNameUpdateModalIsOpen: false,
    userBiographyUpdateModalIsOpen: false,
    userProfilePictureForm: {
      profilePictureBase64String: null,
      profilePictureFileName: null,
    },
    userNameUpdateForm: {
      firstName: '',
      lastName: '',
    },
    userBiographyUpdateForm: {
      biography: '',
    },
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [profileState, profileDispatch] = useReducer(
    profileReducer,
    initialState
  );

  return (
    <ProfileStateContext.Provider value={profileState}>
      <ProfileDispatchContext.Provider value={profileDispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
}

export function useProfileState() {
  return useContext(ProfileStateContext);
}

export function useProfileDispatch() {
  return useContext(ProfileDispatchContext);
}

function profileReducer(profileState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...profileState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...profileState, ...action.feedback };
    }
    case 'GET_FEEDBACK_FROM_LOCAL_STORAGE': {
      return {
        ...profileState,
        feedbackMessage: action.feedbackMessage,
        feedbackVariant: action.feedbackVariant,
      };
    }
    case 'CLEAR_FEEDBACK': {
      return {
        ...profileState,
        feedbackMessage: null,
        feedbackVariant: null,
      };
    }
    case 'OPEN_PROFILE_PICTURE_UPDATE_MODAL': {
      return {
        ...profileState,
        profilePictureUpdateModalIsOpen: true,
        userProfilePictureForm: {
          profilePictureBase64String: profileState.user.profilePictureBase64String,
          profilePictureFileName: profileState.user.profilePictureFileName,
          isDefaultProfilePicture: profileState.user.isDefaultProfilePicture,
        },
      };
    }
    case 'CLOSE_PROFILE_PICTURE_UPDATE_MODAL': {
      return {
        ...profileState,
        profilePictureUpdateModalIsOpen: false,
        userProfilePictureForm: {
          profilePictureBase64String: null,
          profilePictureFileName: null,
        },
      };
    }
    case 'OPEN_USER_NAME_UPDATE_MODAL': {
      return {
        ...profileState,
        userNameUpdateModalIsOpen: true,
        userNameUpdateForm: {
          firstName: profileState.user.firstName,
          lastName: profileState.user.lastName,
        },
      };
    }
    case 'CLOSE_USER_NAME_UPDATE_MODAL': {
      return {
        ...profileState,
        userNameUpdateModalIsOpen: false,
        userNameUpdateForm: {
          firstName: '',
          lastName: '',
        },
      };
    }
    case 'OPEN_USER_BIOGRAPHY_UPDATE_MODAL': {
      return {
        ...profileState,
        userBiographyUpdateModalIsOpen: true,
        userBiographyUpdateForm: {
          biography: profileState.user.biography,
        },
      };
    }
    case 'CLOSE_USER_BIOGRAPHY_UPDATE_MODAL': {
      return {
        ...profileState,
        userBiographyUpdateModalIsOpen: false,
        userBiographyUpdateForm: {
          biography: '',
        },
      };
    }
    case 'UPDATE_PROFILE_PICTURE_BASE_64_STRING_AND_FILE_NAME': {
      return {
        ...profileState,
        userProfilePictureForm: {
          ...profileState.userProfilePictureForm,
          profilePictureBase64String: action.profilePictureBase64String,
          profilePictureFileName: action.profilePictureFileName,
        },
      }
    }
    case 'UPDATE_NEW_USER_FIRST_NAME': {
      return {
        ...profileState,
        userNameUpdateForm: {
          ...profileState.userNameUpdateForm,
          firstName: action.firstName,
        },
      };
    }
    case 'UPDATE_NEW_USER_LAST_NAME': {
      return {
        ...profileState,
        userNameUpdateForm: {
          ...profileState.userNameUpdateForm,
          lastName: action.lastName,
        },
      };
    }
    case 'UPDATE_NEW_USER_BIOGRAPHY': {
      return {
        ...profileState,
        userBiographyUpdateForm: {
          ...profileState.userBiographyUpdateForm,
          biography: action.biography,
        },
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
