import React from 'react';

import {
  useSkillsState,
  useSkillsDispatch,
} from './SkillsContext';

import { createUnrecognizedSkill } from './restApi';

import styles from './NewUnrecognizedSkillForm.module.scss';

const NewUnrecognizedSkillForm = () => {
  const state = useSkillsState();
  const dispatch = useSkillsDispatch();
  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });
  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  return (
    <form id={styles.unrecognizedSkillForm}>
      <input
        className={styles.input}
        type="text"
        placeholder="Type skill here..."
        onChange={
          (e) => {
            dispatch({
              type: 'UPDATE_NEW_SKILL_NAME',
              skillName: e.target.value
            });
          }
        }
        value={state?.newUnrecognizedSkillForm?.skillName}
      />
      <button
        type="button"
        className={styles.btn}
        onClick={() => {
          createUnrecognizedSkill({
            onResponse,
            onError,
            body: {
              newUnrecognizedSkillForm: state?.newUnrecognizedSkillForm,
              authenticityToken,
            },
          });
        }}
      >
        Add Skill
      </button>
    </form>
  );
};

export default NewUnrecognizedSkillForm;
