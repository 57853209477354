import { useEffect } from 'react';

import { useSkillsDispatch } from './SkillsContext';

import { getSkills } from './restApi';

const SkillsLoader = () => {
  const dispatch = useSkillsDispatch();

  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    getSkills({
      onResponse,
      onError,
      recognizedSkillsPageNumber: 1,
      unrecognizedSkillsPageNumber: 1,
      disallowedSkillsPageNumber: 1,
    });
  }, []);

  return null;
};

export default SkillsLoader;
