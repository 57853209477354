import React from 'react';

import PageFeedback from '../../PageFeedback/components/PageFeedback';

import {
  SandboxProvider,
  useSandboxState,
  useSandboxDispatch,
} from './SandboxContext';

const Sandbox = () => {
  return (
    <SandboxProvider>
      <p>A developer sandbox for designing UI components</p>
      <PageFeedback
        useState={useSandboxState}
        useDispatch={useSandboxDispatch}
      />
    </SandboxProvider>
  );
};

export default Sandbox;
