import React from 'react';

import {
  ProfileProvider,
  useProfileState,
  useProfileDispatch
} from './ProfileContext';
import ProfileLoader from './ProfileLoader';
import ProfilePicture from './ProfilePicture';
import ProfileUserName from './ProfileUserName';
import ProfileUserBiography from './ProfileUserBiography';
import PageFeedback from '../../PageFeedback/components/PageFeedback';

import styles from './Profile.module.scss';

const Profile = ({ id }) => {
  return (
    <ProfileProvider
      userId={id}
    >
      <ProfileLoader />
      <ProfilePicture />
      <ProfileUserName />
      <ProfileUserBiography />
      <PageFeedback
        useState={useProfileState}
        useDispatch={useProfileDispatch}
      />
    </ProfileProvider>
  );
};

export default Profile;
