import React, { useEffect } from 'react';
import Feedback from '../../Feedback/components/Feedback';

const PageFeedback = ({
  useState,
  useDispatch
}) => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'GET_FEEDBACK_FROM_LOCAL_STORAGE',
      feedbackMessage: localStorage.getItem("IsibuteFeedbackMessage"),
      feedbackVariant: localStorage.getItem("IsibuteFeedbackVariant"),
    })
  }, []);

  return (
    <Feedback
      message={state?.feedbackMessage}
      variant={state?.feedbackVariant}
      closeFeedback={() => {
        dispatch({ type: 'CLEAR_FEEDBACK' });
      }}
    />
  );
};

export default PageFeedback;
