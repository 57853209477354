import React from 'react';

import { useProfileState, useProfileDispatch } from './ProfileContext';

import { updateCurrentUserBiography } from './restApi';

import styles from './UserBiographyUpdateForm.module.scss';

const UserBiographyUpdateForm = () => {
  const state = useProfileState();
  const dispatch = useProfileDispatch();

  const onResponse = (response) => {
    dispatch({ type: 'ON_RESPONSE', response });
    if (response.feedbackVariant == 'success') {
      dispatch({ type: 'CLOSE_USER_BIOGRAPHY_UPDATE_MODAL' });
    }
  };

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  return (
    <div id={styles.userBiographyUpdateForm}>
      <label className={styles.textareaLabel}>Biography</label>
      <textarea
        class={styles.textarea}
        onChange={(e) => {
          dispatch({
            type: 'UPDATE_NEW_USER_BIOGRAPHY',
            biography: e.target.value,
          });
        }}
        value={state.userBiographyUpdateForm.biography}
      />
      <button
        className={styles.saveBtn}
        onClick={() => {
          updateCurrentUserBiography({
            onResponse,
            onError,
            body: {
              biography: state.userBiographyUpdateForm.biography,
              authenticityToken: document.querySelector("meta[name='csrf-token']").getAttribute("content")
            },
          });
        }}
      >
        Save Update
      </button>
    </div>
  );
};

export default UserBiographyUpdateForm;
